.resourcesRowTitle {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 12px;
  border-bottom: 1px solid grey;
}
.resourcesSubtitle {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 6px;
}
.resourceP1 {
  font-size: 0.95rem;
  margin-bottom: 9px;
}
.resourceP2 {
  font-size: 0.9rem;
  padding: 0 3px;
  margin-bottom: 9px;
}
.resourceP3 {
  font-size: 1rem;
  font-style: italic;
  text-align: center;
  margin-top: 12px;
}
.row1Resources {
  width: 100%;
  min-height: 180px;
  padding: 18px 30px;
  background-color: rgba(245, 245, 245, 0.7);
}
.row2Resources {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  padding: 18px 0;
}
.row3Resources {
  width: 100;
  min-height: 180px;
  padding: 30px;
  background-color: rgba(245, 245, 245, 0.7);
}
.subRow1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.columnAResources {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 0 21px 0 0;
  min-width: 240px;
}
.resourceCard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 45%;
  min-width: 300px;
  min-height: 300px;
  margin: 18px;
  padding: 9px 15px;
  background-color: whitesmoke;
  border: 1px solid grey;
}
.resourceCardLeft {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  min-width: 240px;
}
.resourceCardRight {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 126px;
}
.resourceTitle {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 6px;
}

.bookFrame{
  width: 120px;
  height: 270px; 
  margin: 9px auto;
}
.bookImage {
  border:none; 
  margin:0px;
}
.disclaimer {
  font-size: 0.85rem;
  font-style: italic;
  margin: 15px 0;
}

@media only screen and (min-width: 360px) {
  .resourcesRowTitle {
    font-size: 1.4rem;
  }
  .columnAResources {
    margin: 0 21px;
    min-width: 270px;
  }
}