
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center, dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
  box-sizing: border-box;
}
html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  background-color: whitesmoke; 
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
  background-image: url(./images/ehud-neuhaus-Sne2rCdLz3w-unsplash.jpg);
  background-size: cover;
}

a {
  text-decoration: none;
  color: inherit;
}
.shadowA {
  padding: 0 2px;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.7);
}
.shadowA:hover {
  cursor: pointer;
  border: 1px solid rgba(200, 200, 200, 0.4);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.5);
}
.shadowA:active {
  background-color: rgba(154, 205, 50, 0.6);
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.5);
}
.shadowA2 {
  padding: 0 2px;
  border: 1px solid rgba(245, 245, 245, 0);
  box-shadow: 0px 1px 1px 0px rgba(245, 245, 245, 0.75);
}
.shadowA2:hover {
  cursor: pointer;
  border: 1px solid rgba(245, 245, 245, 0.5);
  box-shadow: 0px 3px 3px 0px rgba(245, 245, 245, 0.5);
}
.shadowA2:active {
  background-color: rgba(154, 205, 50, 0.6);
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.5);
}
.bold {
  font-weight: 700;
}
.mainHeader {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  min-height: 111px;
  background-color: rgba(229, 226, 221, 1);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.mainName {
  flex-basis: 100%;
  flex: 1;
  min-width: 200px;
  color: whitesmoke;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: italic;
  text-align: center;
  background-color: rgba(130, 130, 130, 1);
  margin: 3px 2vw 0 2vw;
  padding: 2px 12px 6px 12px;
  border: 1px solid rgba(130, 130, 130, 1);
  border-radius: 45px;
}
.mainName:hover {
  text-shadow: 0px 0px 9px #d2e8fa, 0px 0px 15px white;
}
.mainName:active {
  color: #fdfca5;
  text-shadow: 0px 0px 12px #fffd75, 0px 0px 18px #d2e8fa, 0px 0px 24px white;
  border-top: 2px solid rgba(90, 90, 90, 0.9);
  border-bottom: 0px solid rgba(130, 130, 130, 1);
}

#subTitle {
  flex-basis: 100%;
  flex: 2;
  text-align: center;
  color: rgba(60, 60, 60, 0.9);
  font-size: 1rem;
  line-height: 1.05rem;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 2px;
}

.headerNav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 100%;
  flex: 4;
  min-width: 270px;
  justify-content: center;
  align-content: center;
  margin: 0 1vw;
  padding-bottom: 4px;
}
.topNav {
  font-size: 0.9rem;
  text-align: center;
  color: rgb(60, 60, 60);
  padding: 0px 9px 3px 9px;
  margin: 1px 0 1px 0;
  border-radius: 2px;
  border: 1px solid rgba(200, 200, 200, 0);
}
.topNav:hover {
  color: whitesmoke;
  background-color: rgba(241, 241, 241, 0.5);
  background-color: rgba(90, 90, 90, 0.5);
  border: 1px solid rgba(90, 90, 90, 0.2);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);

}
.topNav:active {
  color: yellowgreen;
  border-top: 0px solid rgba(200, 200, 200, 0);
  border-bottom: 2px solid rgba(90, 90, 90, 0.1);
}
.App {
  position: fixed; 
  top: 111px; 
  right: 0; 
  bottom: 66px; 
  left: 0;
  overflow:hidden;
  overflow-y: scroll;

}
.pageBottom {
  position: fixed;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 66px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  overflow-y: scroll;
  background-color: transparent;
}
.footerLeft {
  flex-basis: 100%;
  flex: 2;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-width: 141px;
  padding: 2px 0;
  text-align: center;
  color: rgba(245, 245, 245, 0.1);
}
.footerLeft:hover {
  color: rgba(245, 245, 245, 0.5);
}
.footerCenter {
  flex-basis: 100%;
  flex: 3;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-width: 160px;
  padding: 2px 0;
  text-align: center;
  color: rgba(245, 245, 245, 0.1);
}
.footerCenter:hover {
  color: rgba(245, 245, 245, 0.5);
}
.footerRight {
  flex-basis: 100%;
  flex: 5;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2px;
  text-align: center;
  color: rgba(245, 245, 245, 0.1);
}
.footerRight:hover {
  color: rgba(245, 245, 245, 0.5);
}
.pageBottomCredits {
  font-size: 0.7rem;
  margin-top: 2px;
}
.pageBottomInfo {
  font-size: 0.75rem;
  line-height: 1rem;
}
.pageBottomLink {
  font-size: 0.75rem;
  line-height: 0.95rem;
  margin: 1px 6px 3px 6px;
}
.shadowA3 {
  padding: 0 2px;
  border: 1px solid rgba(245, 245, 245, 0);
  box-shadow: 0px 1px 1px 0px rgba(245, 245, 245, 0);
}
.shadowA3:hover {
  cursor: pointer;
  border: 1px solid rgba(245, 245, 245, 0.4);
  box-shadow: 0px 3px 3px 0px rgba(245, 245, 245, 0.4);
}
.shadowA3:active {
  background-color: rgba(56, 221, 23, 0.6);
}

.centered {
  text-align: center;
}
.myUlList {
  margin: 6px 0 9px 0;
  padding-left: 24px;
}
.myUlList2 {
  margin: 0 0 12px 9px;
  padding-left: 9px;
}
.myUlList3 {
  margin: 0 0 9px 0;
  padding-left: 12px;
  list-style-type: none;
}
.paddedRow {
  padding: 18px 30px;
}
.scrollToTop {
  width: 35%;
  margin: 24px auto;
  padding: 6px;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
  background-color: rgba(245, 245, 245, 0.35);
  border-radius: 3px;
}
.scrollToTop:hover {
  cursor: pointer;
  background-color: rgba(154, 205, 50, 0.5);
}

@media only screen and (min-width: 305px) {
  .mainHeader {
    min-height: 90px;
  }
  .App {
    top: 90px; 
  }
}

@media only screen and (min-width: 475px) {
  .headerNav {
    min-width: 240px;
  }
  .contactLabel {
    display: inline-block;
    width: 66px;
    text-align: right;
    margin: 9px 3px 3px 3px;
  }
  #message {
    margin-top: 9px;
  }
  #subTitle {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}


@media only screen and (min-width: 677px) {
  .mainHeader {
    min-height: 60px;
  }
  .mainName {
    margin-bottom: 3px;
  }
  .headerNav {
    justify-content: flex-end;
  }
  .App {
    top: 60px; 
  }
  .scrollToTop {
    width: 25%;
  }
}



@media only screen and (min-width: 715px) {
  .pageBottom {
    height: 56px;
  }
  .App {
    bottom: 56px;
  }
}


@media only screen and (min-width: 800px) {
  .topNav {
    padding: 3px 15px 6px 15px;
  }
}
