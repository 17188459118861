.row1NotFound {
  width: 100%;
  min-height: 90px;
  padding: 30px;
}
.row2NotFound {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background-color: rgba(245, 245, 245, 0.2);
}
.notFoundPageTitle {
  font-size: 1.4rem;
  font-weight: 600;
}
.pageLinkCard {
  height: 210px;
  width: 180px;
  margin: 30px 15px;
  padding: 9px 15px;
  background-color: rgba(245, 245, 245, 0.7);
  box-shadow: 0px 11px 21px -8px rgba(0, 0, 0, 0.5);
}
.pageLinkCard:hover {
  cursor: pointer;
  background-color: rgba(154, 205, 50, 0.5);
}
.pageLinkCard:active {
  background-color: rgba(123, 123, 123, 0.5);
}

.pageLinkTitle {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 9px;
}
.pageLinkP {
  font-size: 0.95rem;
  margin-bottom: 9px;
}
