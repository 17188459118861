#homeTop {
  width: 100%;
}
#startMenuRow {
  position: fixed;
  top: 111px;
  right: 18px;
  left: 18px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
  background-color: rgba(229, 226, 221, 0.85);
  box-shadow: 0px 0px 4px 2px rgba(60, 60, 60, 0.8);
}
.startMenuOption {
  flex-basis: 100%;
  flex: 1;
  min-height: 30px;
  font-size: 0.75rem;
  line-height: 0.85rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(60, 60, 60, 0.9);
  padding: 3px 9px 4px 9px;
  border: 1px solid  rgba(60, 60, 60, 0.4);
  position: relative;
  z-index: 3;
}
.startMenuOption:hover {
  color: rgba(245, 245, 245, 1);
  background-color: rgba(100, 149, 237, 0.3);
}
.startMenuOption:active {
  border-top: 2px solid  rgba(60, 60, 60, 0.4);
  border-bottom: 0px solid  rgba(60, 60, 60, 0.4);
  box-shadow: 0px 1px 9px 5px rgba(210, 232, 250, 0.4), 0px 2px 18px 9px rgba(255, 255, 255, 0.6);

}
@media only screen and (min-width: 305px) {
  #startMenuRow {
    top: 90px;
  }  
}

@media only screen and (min-width: 475px) {
  .startMenuOption {
    min-height: 33px;
    font-size: 0.8rem;
    line-height: 0.85rem;
  }

}

@media only screen and (min-width: 677px) {
  #startMenuRow {
    top: 60px;
  }
  .startMenuOption {
    min-height: 36px;
    font-size: 0.9rem;
    line-height: 0.95rem;
  }
}
