.row1Courses {
  width: 100%;
  min-height: 180px;
  padding: 21px 15px 21px 9px;
  background-color: rgba(245, 245, 245, 0.7);
  color: rgb(40, 40, 40);
}
#classesInfo {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 100%;
  min-width: 270px;
  margin: 0 auto;
  padding: 3vh 3vw 0 3vw;
}

.coursesRowTitle {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 3px;
  border-bottom: 1px solid grey;
}
.courseP1 {
  font-size: 1rem;
  margin-bottom: 4px;
}
.courseP2 {
  font-size: 1rem;
  margin-top: 9px;
  margin-bottom: 12px;
}
.circle {
  display: inline-block;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  margin: 3px 3px 0 3px;
  background-color: rgb(131, 175, 43);
  background-image: linear-gradient(rgb(176, 252, 100),rgb(77, 153, 2));
  box-shadow: 0px 4px 6px #888888;
}
.classPoints {
  line-height: 1.3rem;
  margin: 6px 0;
  padding-left: 24px;
  padding-right: 12px;
}
.ulEndSpan{
  margin-left: 18px;
}
.arrowSpan {
  font-size: 2rem;
  vertical-align: middle;
}
.row2Courses {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  padding: 21px 0;
}
.courseCard {
  width: 90%;
  min-width: 240px;
  max-width: 300px;
  min-height: 330px;
  margin: 15px;
  padding: 9px 15px 0px 15px;
  border-radius: 4px;
  background-color: whitesmoke;
  border: 1px solid lightgrey;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.5);
  position: relative;
}
.courseCard:hover {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.5);
  top: -3px;
}
.cardTopA {
  border-top: 12px solid rgb(226, 126, 255);
}
.cardTopB {
  border-top: 12px solid rgb(223, 193, 45);
}
.cardTopC {
  border-top: 12px solid rgb(137, 137, 252);
}
.courseTitle {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 700;
  margin: 0 -9px 6px -9px;
  padding: 6px 9px 9px 9px;
}
.courseTitle:hover {
  cursor: pointer;
  background-color: rgb(230, 230, 230);
}
.courseData {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 1.1 rem;
  font-weight: 700;
  border-bottom: 1px solid grey;
  margin-bottom: 9px;
}
.courseTime {
  width: 50%;
  text-align: left;  
}
.courseSize {
  width: 50%;
  text-align: right;
}
.courseDetails {
  font-size: 1rem;
  line-height: 1.2rem;
  margin-bottom: 9px;
}

.row3Courses {
  width: 100;
  min-height: 111px;
  padding: 21px;
  background-color: rgba(245, 245, 245, 0.5);
  text-align: center;
}
.coursesQ {
  margin-top: 9px;
}
#classList {
  margin-top: 18px;
}
.coachingListItem {
  margin-bottom: 18px;
}
.signUpBtn1 {
  width: 240px;
  margin: 9px 0;
  padding: 9px 15px;
  border-radius: 6px;
}
.signUpBtn1:hover {
  cursor: pointer;
  background-color: rgb(160, 160, 160);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.5);
}
.signUpButton1:active {
  background-color: rgb(97, 226, 85);
}
.signUpBtn2 {
  width: 200px;
  margin: 0px auto;
  padding: 9px 15px;
  border-radius: 6px;
}
.signUpBtn2:hover {
  cursor: pointer;
  background-color: rgb(160, 160, 160);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.5);
}
.signUpBtn2:active {
  background-color: rgb(97, 226, 85);
}

@media only screen and (min-width: 475px) {
  .courseCard {
    width: 33%;
  }
}


@media only screen and (min-width: 600px) {
  .row1Courses {
    padding: 21px 30px;
  }
  .coursesRowTitle {
    font-size: 1.4rem;
    margin-bottom: 3px;
  }
  .courseP1 {
    font-size: 1.05rem;
    margin-bottom: 6px;
  }
  .courseP2 {
    font-size: 1.05rem;
    margin-top: 12px;
    margin-bottom: 15px;
  }
  .circle {
    height: 15px;
    width: 15px;
    margin: 6px 3px 0 3px;
  }
  .classPoints {
    line-height: 1.7rem;
    margin: 6px 0;
    padding-left: revert;
    padding-right: 0;
  }
  .row2Courses {
    padding: 60px 0;
  }  
}

@media only screen and (min-width: 750px) {
  #classesInfo {
    width: 90%;
  }
}